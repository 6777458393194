<template>
  <p>กำลังเข้าสู่ระบบ...</p>
</template>

<script>
import { HTTP } from "@/axios.js"
import Auth from "@/services/Auth"
import Api from "@/services/apiServices"
import CryptoJS from 'crypto-js'

export default {
  name: "Callback",
  data() {
    return {
    }
  },
  mounted() {
    this.filterUser(this.$route.query.code)
  },
  methods: {
    async filterUser(queryCode) {
      const res = await Auth.getTokenFromBe(process.env.VUE_APP_REDIRECT_URI, queryCode)
      if (res.status) {
        res.data[0] = JSON.parse(res.data[0])
        let user = await Auth.getUserInGraph(res.data[0].access_token)
        if (!(user instanceof Error)) {
          localStorage.setItem("access_token", res.data[0].access_token)
          if (user) {
            let userRole = await this.checkUserRole(user.mail)
            console.log("userRole" , userRole)
            userRole = CryptoJS.AES.encrypt(userRole, process.env.VUE_APP_LOCALSTORAGE_KEY).toString()
            console.log("set localStorage" ,  userRole)
            localStorage.setItem("Admin", userRole)
            localStorage.setItem("email", user.mail)
            localStorage.setItem("name", user.displayName)
            localStorage.setItem("AccType", user.jobTitle)
            if (user.jobTitle !== "Employee") {
              this.$router.push({ path: "/pages/nopermission" })
            } else {
              const nextPath = `${localStorage.getItem("beforepath") ? localStorage.getItem("beforepath") : "dashboard"}`
              localStorage.removeItem("beforepath")
              this.$router.push({ path: nextPath })
            }
          } else {
            localStorage.removeItem("access_token")
            localStorage.removeItem("email")
            localStorage.removeItem("name")
            localStorage.removeItem("AccType")
            localStorage.removeItem("code")
            localStorage.removeItem("userProfile")
            localStorage.removeItem("Admin")
            this.$router.push({ path: "/pages/500" })
          }
        }
      } else {
        this.$router.push({ path: "/pages/500" })
      }
    },
    async getStudentReportEachUser(email) {
      var response
      await HTTP.get(`api/event/leave/user?eventid=${this.eventID}&email=${email}`)
        .then((res) => {
          if (res.data) {
            let resData = JSON.parse(JSON.stringify(res.data))
            response = resData
          }
        })
        .catch((e) => {
          console.log(e)
        })
      return response
    },
    async checkUserRole(email) {
      let userRole = await Api.getUserRole(email)
      if (userRole) {
        let concludeRole = ""
        if (userRole.status) {
          if (userRole.data === null) {
            concludeRole = "user"
          } else {
            for (let i = 0; i < userRole.data.permission.length; i++) {
              switch (userRole.data.permission[i]) {
                case "super-admin":
                  return "super-admin"
                case "faculty-admin":
                  concludeRole = "faculty-admin"
                  break
                default:
                  if (concludeRole === "") {
                    concludeRole = "user"
                  }
                  break
              }
            }
          }
          return concludeRole
        }
      }
    },
  },
}
</script>
